<template lang="">
  <div>
    <h6 class="heading-small text-muted mb-4">ข้อมูลช่องทาง</h6>
    <div class="row">
    <div class="col-md-4 offset-md-4">
      <div class="mb-1">
        <label class="form-label">ชื่อช่องทาง</label>
          <base-input name="name" placeholder="ชื่อช่องทาง" v-model="item.name" :rules="{required: true}" >
          </base-input>
        </div>
      </div>
      <div class="col-sm-12">
        <hr class="my-4">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'channel-view-form-channel',
  props: [
    'item'
  ]
}
</script>
